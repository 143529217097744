import React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "components/layout/default";
import layoutStyle from "styles/layout.module.css";
import radiusTopCapImage from "images/product-radius-top-cap.svg";
import radiusBottomCapImage from "images/product-radius-bottom-cap.svg";
import radiusCapGrayTopSmImage from "images/radius-cap-gray-top-sm.svg";
import radiusCapGrayBottomSmImage from "images/radius-cap-gray-bottom-sm.svg";
import radiusCapBlackBottomSmImage from "images/radius-cap-black-bottom-sm.svg";
import wardenImage from "images/platform/storyfier/storyfier-main-logo.png";
import iconImage1 from "images/platform/storyfier/Storyfier-Icon01.svg";
import iconImage2 from "images/platform/storyfier/Storyfier-Icon02.svg";
import iconImage3 from "images/platform/storyfier/Storyfier-Icon03.svg";
import iconImage4 from "images/platform/storyfier/Storyfier-Icon04.svg";
import iconImage5 from "images/platform/storyfier/Storyfier-Icon05.svg";
import iconImage6 from "images/platform/storyfier/Storyfier-Icon06.svg";

import StoryfierImage1 from "images/platform/storyfier/Storyfier-Image01.svg";
import StoryfierImage2 from "images/platform/storyfier/Storyfier-Image02.svg";
import Img from "components/image";
import { IMAGE_BACKGROUND_PROPS } from "constants/index";
import style from "styles/pages/products.module.css";
import styles from "styles/pages/solutions.module.css";

function CisoPage(props) {
  let [mainImageLoaded, setMainImageLoaded] = React.useState(false);
  let mainImageRef = React.useRef();

  React.useEffect(() => {
    setMainImageLoaded(mainImageRef.current.complete);
  }, [mainImageRef]);

  function handleMainImageLoad() {
    setMainImageLoaded(true);
  }

  function renderVerticalLine() {
    let height = "0%";
    if (mainImageLoaded) height = "64vh";
    return (
      <div
        className={`${style.verticalLine} absolute top-0 w-px bg-white hidden md:block`}
        style={{ height }}/>
    );
  }

  function renderSection1() {
    return (
      <section className="relative min-h-screen">
        <div className="container mx-auto px-8 pt-4 pb-16 md:py-32 z-10 relative">
          <div className="text-center px-8 md:px-0">
            <img
              ref={mainImageRef}
              className="inline-block pointer-events-none"
              src={wardenImage}
              width={340}
              alt=""
              onLoad={handleMainImageLoad}/>
          </div>
          <div className="relative flex">
            {renderVerticalLine()}
            <div className="md:w-1/2 flex-none"/>
            <div className="ml-0 md:ml-16">
              <h3 className="font-bold text-4xl lg:text-6xl leading-tight mt-4">
                Storyfier
              </h3>
              <p>
                Embed raw security data into your competitive strategy on the
                Horangi platform.
              </p>
            </div>
          </div>
        </div>
        <Img
          {...IMAGE_BACKGROUND_PROPS}
          objectFit="contain"
          alt="Background"
          fluid={props.data.backgroundImage.childImageSharp.fluid}/>
      </section>
    );
  }

  function renderEgg() {
    return (
      <section className="relative text-white mt-40 mb-40">
        <img
          src={StoryfierImage1}
          style={{zIndex: 5500}}
          alt="Background"
          className="w-full pointer-events-none absolute z-20 -mt-20 xs:-mt-20 xl:-mt-64 xxl:-mt-96"/>
        <div style={{zIndex: 5000 }} className="bg-black relative pb-5 sm:pb-0 md:pb-20 mt-0 sm:-mt-px md:mt-128">
          <div className="mx-auto px-8 pt-16 xs:pt-48 md:pt-48 relative">
            <div className="sm:flex">
              <div className="sm:w-1/2 flex items-center xs:justify-center md:justify-end">
                <div className="w-full sm:w-2/3">
                  <h2 className="font-bold text-sm sm:text-xl mt-20 sm:mt-0 leading-tight uppercase">
                    One Dashboard
                  </h2>
                  <h3 className="font-serif italic text-3xl md:text-4xl xxl:text-6xl leading-tight mt-4 whitespace-pre-wrap">
                    Integrated Across The Horangi Suite of Products &amp;
                    Services
                  </h3>
                  <p className="mt-10 text-sm mb-10">
                    Storyfier is Horangi’s integrated SaaS security platform.
                    Spend less time formatting security data and more time on
                    secure innovation. Get always-on visibility of your top
                    risks and vulnerabilities on Storyfier’s single pane of
                    glass platform display.
                  </p>
                  <a className="btn bg-orange" href="https://app.horangi.com/registration/marketplace">
                    Start 14-Day Trial
                  </a>
                </div>
              </div>
              <div className="sm:w-1/2">
                <img src={StoryfierImage2} alt=""/>
              </div>
            </div>
          </div>
        </div>
        <img
          style={{ marginTop: -3}}
          src={radiusCapBlackBottomSmImage}
          alt="Background"
          className="w-full z-1 pointer-events-none relative bg-white"/>
      </section>
    );
  }

  function renderSection2() {
    const subHeaderText = (
      <h3 className="font-serif italic text-md pb-20 p-4 mt-20 md:mt-4 sm:text-4xl lg:text-6xl text-center leading-tight mb-64 whitespace-pre-wrap w-5/6">
        Identify The Critical Insights From The Noise In Your Production
        Environment
      </h3>
    );
    return (
      <section className="relative ">
        <div className="flex justify-center -mb-96 xxl:-mb-48 lg:py-0 relative text-black">
          {subHeaderText}
        </div>
      </section>
    );
  }

  function renderSection3() {
    return (
      <section className="relative bg-white text-black flex justify-center text-black bg-white pb-40">
        <div className="flex flex-wrap container pt-0 sm:pt-40">
          <div key={`item-${1}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage1}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                AVAILABLE WITH ANY HORANGI SOFTWARE
              </h2>
              <p className="mt-2">
                Use either Warden, Code Scanner, or Web Scanner to experience
                Storyfier’s full-feature platform.
              </p>
            </div>
          </div>
          <div key={`item-${2}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage2}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                UNIFYING RAW SECURITY DATA
              </h2>
              <p className="mt-2">
                Integrate data from Warden, Code Scanner, Web Scanner, Trainer,
                Penetration Testing, and Vulnerability Assessments.
              </p>
            </div>
          </div>
          <div key={`item-${3}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage3}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                ENABLE COMPLIANCE REPORTING
              </h2>
              <p className="mt-2">
                Benchmark your security posture against recognized frameworks
                and regulations such as ISO 27001, OWASP, and PCI DSS.
              </p>
            </div>
          </div>
          <div key={`item-${4}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage4}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                CONTINUOUS SECURITY ASSURANCE
              </h2>
              <p className="mt-2">
                Get real-time visibility of vulnerabilities across your source
                code, cloud infrastructure, and web applications. (Via Slack and
                Email)
              </p>
            </div>
          </div>
          <div key={`item-${5}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage5}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                SCALE YOUR SECURITY ON DEMAND
              </h2>
              <p className="mt-2">
                Horangi consultants can advise on compliance, strategy, and
                support on cybersecurity services and audits.
              </p>
            </div>
          </div>
          <div key={`item-${6}`} className="md:w-1/3">
            <div className="p-10">
              <img
                src={iconImage6}
                alt="Icon"
                width={48}
                className="mb-6 pointer-events-none"/>
              <h2 className="font-bold text-lg leading-tight">
                INTEGRATED GITHUB, GITLAB, BITBUCKET, AND JIRA WORKFLOW
              </h2>
              <p className="mt-2">
                Enable DevOps to work seamlessly. Export the findings report
                directly onto their commonly used repositories to remediate
                faster.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function renderSection7() {
    return (
      <section className="relative bg-white text-black my-16 sm:my-32">
        <img
          src={radiusCapGrayTopSmImage}
          alt="Background"
          className="w-full z-1 pointer-events-none"/>
        <div>
          <div
            className="h-128 md:h-64 xxl:h-112"
            style={{ backgroundColor: "#F7F7F7" }}>
            <div className="xs:w-full md:w-3/6 absolute">
              <img
                src={props.data.screenImage.childImageSharp.fluid.src}
                className="pointer-events-none mt-128 sm:mt-20 md:mt-10 md:ml-20 h-48 sm:h-128 xxl:h-auto ml-20"
                alt=""/>
            </div>
            <div
              className="xs:w-full md:w-3/6 md:right-0 absolute xs:mt-96 md:-mt-64 md:mr-10 xs:p-10 md:p-0 lg:p-40">
              <h2 className="font-bold text-sm sm:text-xl leading-tight uppercase mt-5 text-center sm:text-left">
                ANATOMY OF A STORYFIER FINDING
              </h2>
              <h3 className="font-serif italic text-3xl sm:text-4xl xxl:text-6xl leading-tight mt-4 whitespace-pre-wrap text-center p-5 md:p-0 sm:text-left">
                Get More From Every Storyfier Scan
              </h3>
              <p className="mt-4 sm:pr-20 xxl:text-xl text-center sm:text-left p-5 md:p-0">
                A single mistake like an incorrect S3 bucket permission setting
                is sufficient for an attacker to gain access to your
                confidential data. Storyfier’s findings outline the business
                impact and severity of your vulnerable targets, helping you
                patch them faster with remediation steps.
              </p>
              <div className="w-full flex justify-center sm:justify-start ml-0">
                <a href={"https://app.horangi.com/registration/marketplace"} className="btn btn-orange mt-6">
                  Start 14-Day Trial
                </a>
              </div>
            </div>
          </div>
        </div>
        <img
          src={radiusCapGrayBottomSmImage}
          alt="Background"
          className="w-full z-1 pointer-events-none"/>
      </section>
    );
  }

  function renderSection8() {
    return (
      <section className={`relative text-black ${styles.storyfier_contract_us}`}>
        <div className={`container mx-auto z-10`}>
          <h3 className="text-lg text-center leading-tight p-4 md:p-0 md:mb-5 font-bold uppercase">
            Start Tracking Your Security Posture On Storyfier
          </h3>
          <p className="max-w-xl mx-auto text-center whitespace-pre-wrap p-5 md:p-0">
            All your data scans are logged in Storyfier, helping you track
            against your baseline as you tackle your security risks
            progressively.
          </p>
          <div className="text-center">
            <a href={"/contact"} className="btn btn-orange mt-6 inline-block">
              Contact Us
            </a>
          </div>
        </div>
      </section>
    );
  }

  return (
    <DefaultLayout
      location={props.location}
      title="Horangi Storyfier"
      description="Identify the critical insights from the noise in your production environment. Embed raw security data into your competitive strategy on the Horangi platform.">
      <div className={layoutStyle.headerPlaceholder}/>
      {renderSection1()}
      <img
        src={radiusTopCapImage}
        alt="Background"
        className="w-full z-1 pointer-events-none"/>
      <div className="relative z-10 -mt-32 sm:-mt-32 overflow-hidden">
        <div
          className="bg-white absolute w-full"
          style={{ top: "127px", bottom: "127px" }}/>
        {renderSection2()}
        {renderEgg()}
        {renderSection3()}
        {renderSection7()}
        {renderSection8()}
      </div>
      <img
        src={radiusBottomCapImage}
        alt="Background"
        className="w-full z-1 -mt-32 pointer-events-none"/>
      <div className="pb-48"/>
    </DefaultLayout>
  );
}

export default React.memo(CisoPage);

export const QUERY = graphql`
  query PlatformStoryfierQuery {
    pageContent: file(
      relativePath: { eq: "solution-pages/penetrationtesting.json" }
    ) {
      childProductPagesJson {
        title
        description
      }
    }
    backgroundImage: file(
      relativePath: { eq: "background-products-item.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    screenImage: file(relativePath: { eq: "computer-screen-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
